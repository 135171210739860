
import { getInterestsList } from '@/api/interests';
import { getRoleList } from '@/api/role';
import {
  IInterestCompany,
  IInterests,
  IMyHomepageList,
  IRole,
} from '@/types';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';
import { getHomepageList } from '@/api/myHomepage';
import { UserModule } from '@/store/user';
import {
  addInterestCompany,
  deleteInterestCompany,
  getInterestCompanyList,
  getMyInterestCompanyList,
} from '@/api/interestCompany';

@Component({
  name: 'HomepageList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) type!: string

  mounted() {
    this.init();
  }

  @Watch('searchInterestsList')
  private handleChangeMenu() {
    this.init();
    this.listQuery = {
      page: 0,
      size: 16,
      roleCode: '',
      searchValue: '',
      orderBy: 'new',
      interestsList: '',
    };
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private role: IRole | null = null;

  private interestsList: IInterests[] = [];

  private homepageList: IMyHomepageList[] = [];

  private interestCompanyList: string[] = [];

  private listQuery = {
    page: 0,
    size: 16,
    interestsList: '',
    roleCode: '',
    searchValue: '',
    orderBy: 'new',
  };

  private searchInterestsList: string[] = [];

  private listTotal = 0;

  private totalPages = 0;

  private init() {
    let index = 0;
    if (this.type === 'makerspace') index = 0;
    if (this.type === 'startup') index = 1;
    if (this.$route.query) {
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query,
      };
      if (this.$route.query.interestsList && typeof (this.$route.query.interestsList) === 'string') {
        this.searchInterestsList = (this.$route.query.interestsList as any).split(',');
      }
    }
    getRoleList().then((res: any) => {
      if (res.data[index]) {
        this.role = res.data;
        this.listQuery.roleCode = res.data[index].roleCode;
        getInterestsList(res.data[index].roleCode).then((response) => {
          this.interestsList = response.data;
          this.getHomepageList();
        });
      }
    });
    getMyInterestCompanyList().then((res) => {
      this.interestCompanyList = res.data;
    });
  }

  private getHomepageList() {
    if (this.searchInterestsList && this.searchInterestsList.length > 0) {
      this.listQuery.interestsList = this.searchInterestsList.join(',');
    }
    getHomepageList(this.listQuery).then((res) => {
      this.homepageList = res.data.content;
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleClickAll() {
    setTimeout(() => {
      if (this.searchInterestsList.indexOf('all') < 0) {
        this.searchInterestsList = [];
      } else {
        this.interestsList.forEach((i) => {
          this.searchInterestsList.push(i.uid);
        });
      }
    }, 100);
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getHomepageList();
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getHomepageList();
  }

  private isInterestCompany(uid: string) {
    return this.interestCompanyList.indexOf(uid) > -1;
  }

  private handleClickInterest(uid: string, follow: boolean) {
    if (follow) {
      addInterestCompany(uid).then(() => {
        this.interestCompanyList.push(uid);
      });
    } else {
      deleteInterestCompany(uid).then(() => {
        const index = this.interestCompanyList.indexOf(uid);
        if (index > -1) this.interestCompanyList.splice(index, 1);
      });
    }
  }

  private getOnclass(order: string) {
    if (this.listQuery.orderBy === order) return 'sort_on';
    return '';
  }

  private handleClickOrder(order: string) {
    this.listQuery.orderBy = order;
    this.getHomepageList();
  }

  private getRouterName() {
    if (this.type === 'makerspace') return 'MakerspaceDetail';
    if (this.type === 'startup') return 'StartupDetail';
    return '';
  }

  private searchReset() {
    this.listQuery.searchValue = '';
  }
}
